import { defineStore } from "pinia";
import axios from "axios";

export const useFacultiesStore = defineStore("faculties", {
  state: () => ({
    faculties: {},
  }),
  getters: {},
  actions: {
    async fetchFaculties() {
      let t = this;

      if (Object.entries(this.faculties).length > 1) {
        return;
      }

      return new Promise((resolve, reject) => {
        axios.post("faculties").then((result) => {
          result.data.data.forEach((element) => {
            if (!t.faculties[element.slug]) {
              t.faculties[element.slug] = element;
            }
          });
          resolve(result);
        });
      });
    },

    async fetchFacultyTitulations(slug) {
      let t = this;

      if (this.faculties[slug] && this.faculties[slug]["titulations"]) {
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`faculties/show/${slug}?c-load[]=titulations`)
          .then((result) => {
            this.faculties[slug] = result.data;

            this.faculties[slug]["titulations"] = this.faculties[slug][
              "titulations"
            ].reduce((acc, el) => {
              acc[el.slug] = el;
              return acc;
            }, {});

            resolve(result);
          });
      });
    },

    async fetchPhotos(slugFaculty, slugTitulation) {
      let t = this;

      if (t.faculties[slugFaculty]["titulations"] && t.faculties[slugFaculty]["titulations"][slugTitulation]["photos"]) {
        return;
      }

      let data = {
        columns: [
          {
            name: "titulation",
            onlyWith: "true",
          },
        ],
        filters: [
          {
            name: "titulation.slug",
            search: slugTitulation,
          },
        ],
        order: [
          {
            name: "end_year",
            direction: "asc",
          },
        ],
      };

      return new Promise((resolve, reject) => {
        axios.post("photos", data).then((result) => {
          if(!t.faculties[slugFaculty]) {
            t.faculties[slugFaculty] = {
              titulations : {}
            }    
          }
          if(!t.faculties[slugFaculty]["titulations"][slugTitulation]) {
            let x = data.data.length > 0 ? data.data[0]['titulation'] : {};
            t.faculties[slugFaculty]["titulations"][slugTitulation] = x;
          }
          t.faculties[slugFaculty]["titulations"][slugTitulation]["photos"] =
            result.data.data;
          resolve(result);
        });
      });
    },
  },
});

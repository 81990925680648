<template>
  <Header v-if="authStore.login"></Header>
  <div :class="(authStore.login) ? 'body-app' : 'body-app login'">
    <popup v-if="dialogueStore.dialogue.hasOwnProperty('response')"></popup>
    <transition name='custom-classes' enter-active-class="animate__animated animate__fadeIn animate__slow">
      <router-view />
    </transition>
  </div>
  <section class="footer" v-if="authStore.login">
    <Footer></Footer>
  </section>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import popup from '@/components/popup.vue';
import { auth } from '@/store/auth';
import { useDialogueStore } from '@/store/dialogue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    popup
  },
  setup() {
    const authStore = auth();
    const dialogueStore = useDialogueStore();
    return {
      authStore,
      dialogueStore
    }
  },
}
</script>

<style lang="scss">
@import "@/styles/general.scss";
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
</style>
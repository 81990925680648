<template lang="html">
  <section class="footer">
    <div class="container">
      <router-link :to="{name:'home'}" class="logo-link">
        <img src="/img/logo-white.svg" alt="Alumni Francisco de Vitoria">
      </router-link>
      <div class="rss-container">
        <a href="https://www.facebook.com/AlumniUFV" target="_blank" class="column-center" rel="noopener noreferrer">
          <i class="fa fa-facebook" aria-hidden="true"></i>
        </a>
        <a href="https://twitter.com/alumniufv" target="_blank" class="column-center" rel="noopener noreferrer">
          <i class="fa fa-twitter" aria-hidden="true"></i>
        </a>
        <a href="https://www.instagram.com/alumniufv" target="_blank" class="column-center" rel="noopener noreferrer">
          <i class="fa fa-instagram" aria-hidden="true"></i>
        </a>
        <a href="https://vimeo.com/alumniufv" target="_blank" class="column-center" rel="noopener noreferrer">
          <i class="fa fa-vimeo" aria-hidden="true"></i>
        </a>
        <a href="https://www.linkedin.com/groups/4966122/" target="_blank" class="column-center" rel="noopener noreferrer">
          <i class="fa fa-linkedin" aria-hidden="true"></i>
        </a>
      </div>
    </div>

  </section>
</template>

<script lang="js">

  export default {
    name: 'footer'
  }
</script>
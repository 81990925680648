<template>
    <div class="popup" v-if="showMenuFull">
        <div class="container-popup">
            <button class="close-popup" @click="showMenuFull = false">
                <i class="fa fa-times"></i>
            </button>
            <div class="text-popup">
                <span v-for="(message, key) in getMessages()" :key="key">{{ message }}</span>
            </div>
        </div>
    </div>
</template>
  
<script>
import { ref } from "vue";
import { useDialogueStore } from '@/store/dialogue';
import { useI18n } from "vue-i18n";

export default {
    name: 'popup',
    props: [],
    components: {},
    setup() {
        const showMenuFull = ref(true);
        const dialogueStore = useDialogueStore();
        const { t } = useI18n();

        //methods
        const toggleShowMenuFull = () => {
            showMenuFull.value = !showMenuFull.value;
        };
        const getMessages = () => {
            let messages = [];

            if (!dialogueStore.dialogue.response) {
                messages.push(t('validations.not_photo'))
            } else if (Array.isArray(dialogueStore.dialogue.response.data.messages[0]['message'])) {
                dialogueStore.dialogue.response.data.messages[0]['message'].forEach(msg => messages.push(msg));
            } else {
                messages.push(dialogueStore.dialogue.response.data.messages[0]['message']);
            }

            return messages;
        };

        return {
            showMenuFull,
            toggleShowMenuFull,
            getMessages
        }
    },
}
</script>
import { defineStore } from 'pinia'
export const auth = defineStore('auth', {
    state: () => (
        {
            login: null,
            token: null,
        }
    ),
    getters: {

    },
    actions: {

    }
})
<template>
  <div class="header">
    <div class="container">
      <router-link :to="{ name: 'home' }" class="logo-link" :home="headerHome">
        <img src="/img/logo.svg" alt="Alumni Francisco de Vitoria">
      </router-link>
      <!--       <div class="container-search" v-if="this.$route.name == 'faculty' || this.$route.name == 'photos'">
        <button class="search">
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
        <input type="text" class="tool-search" :placeholder="$tm('placeholders.search')">
      </div> -->
      <div class="tags-container" v-if="this.$route.name == 'photos'">
        <span class="degree">
          {{ $tm("fields.degree") }}
        </span>
        <span class="double-degree">
          {{ $tm("fields.double_degree") }}
        </span>
        <span class="not-force">
          {{ $tm("fields.not_force") }}
        </span>
      </div>
      <button class="menu" @click="toggleShowMenuFull()"
        v-if="this.$route.name == 'faculty' || this.$route.name == 'photos'">
        <img src="/img/menu.svg" alt="Menú">
      </button>
    </div>
    <div class="menu-popup" v-if="showMenuFull">
      <div class="container-menu">
        <button class="close-menu" @click="showMenuFull = false">
          <i class="fa fa-times"></i>
        </button>
        <div class="menu-first">
          <div class="first-item" v-for="(faculty, key) in store.faculties" :key='key'>
            <div class="btn-first-item">
              <router-link @mouseenter="changeHover(faculty)" :to="{
                  name: 'faculty',
                  params: {
                    faculty: key,
                  },
                }">
                <span>{{ faculty.name }}</span>
              </router-link>
              <button :active="showMenuSecondRes[faculty.slug]" @click="toogleMenuSecondRes(faculty.slug, faculty)">
                <i class="fa fa-chevron-down"></i>
              </button>
            </div>
            <div :id="faculty.slug" class="menu-second-responsive"
              v-if="optionHover != '' && showMenuSecondRes[faculty.slug]">
              <router-link :to="{
                  name: 'photos',
                  params: {
                    faculty: faculty.slug,
                    titulation: key
                  },
                }" class="link-titulation" v-for="(titulation, key) in store.faculties[faculty.slug].titulations"
                :key='key'>
                <span>{{ titulation.name }}</span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="menu-second" v-if="optionHover != ''">
          <router-link :to="{
              name: 'photos',
              params: {
                faculty: optionHover.slug,
                titulation: key
              },
            }" class="link-titulation" v-for="(titulation, key) in store.faculties[optionHover.slug].titulations"
            :key='key'>
            <span>{{ titulation.name }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import { useFacultiesStore } from '@/store/faculties';
import { onUpdated } from "vue";
import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  name: 'header',
  props: [],
  components: {},
  setup() {
    const showMenuFull = ref(false);
    const optionHover = ref('');
    const store = useFacultiesStore();
    store.fetchFaculties();
    const route = useRoute();
    const showMenuSecondRes = reactive({});
    const headerHome = ref(false);

    onUpdated(() => {
      if (route.name != 'home') {
        Object.values(store.faculties).forEach(element => {
          store.fetchFacultyTitulations(element.slug);
        });
      }
    });

    //methods
    const toggleShowMenuFull = () => {
      showMenuFull.value = !showMenuFull.value;
    };
    const changeHover = (data) => {
      optionHover.value = data;
    };
    const scrollSection = (params) => {
      showMenuFull.value = false
      setTimeout(function () {
        document.getElementById(params).scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }, 200);
    };
    const toogleMenuSecondRes = (id, data) => {
      optionHover.value = data;
      showMenuSecondRes[id] = !showMenuSecondRes[id];
    }

    watch(route, () => {
      showMenuFull.value = false;
      headerHome.value = route.name === 'home' ? true : false;
    })

    return {
      showMenuFull,
      toggleShowMenuFull,
      optionHover,
      changeHover,
      scrollSection,
      store,
      showMenuSecondRes,
      toogleMenuSecondRes,
      headerHome
    }
  },
}
</script>
import {
  createRouter,
  createWebHistory
} from 'vue-router'
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      requiresAnonimous: true,
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/facultad/:faculty',
    name: 'faculty',
    props: true,
    component: () => import("@/views/view-faculty"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/facultad/:faculty/:titulation',
    name: 'photos',
    props: true,
    component: () => import("@/views/view-photos"),
    meta: {
      requiresAuth: true,
    },
  },

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }

  },
  routes
})
import { auth } from '@/store/auth';
export default router
router.beforeEach((to, from, next) => {
  const store = auth();
  if (to.meta && to.meta.requiresAuth && store.login == null) {
    next({
      name: 'login'
    })
  } else if (to.meta && to.meta.requiresAnonimous && store.login) {
    next({
      name: 'home'
    })
  }
  next()
})
import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import { i18n } from "./i18n";
import Vue3Lottie from "vue3-lottie";
import App from "./App.vue";
import "./vee-validate";
import { auth } from "./store/auth";
import { useDialogueStore } from "./store/dialogue";

//Configure Axios
axios.defaults.baseURL = process.env.VUE_APP_BACK;
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  config.headers.Authorization = 'Bearer ' + auth().token;
  return config;
})
axios.interceptors.response.use(
  (response) => {
    const dialogueStore = useDialogueStore();
    if (response.data.status !== null && response.data.status === false) {
      dialogueStore.dialogue.response.data.messages[0]["message"] = response.data.error ?? "Ha surgido un error no esperando al realizar su petición. Contacte con soporte";
      return Promise.reject(response);
    }
    return response;
  },
  (error) => {
    if (error.response.status == 401) {
      window.location.replace('login');
      return Promise.reject(error);
    }
    const dialogueStore = useDialogueStore();
    delete error.response.config;
    dialogueStore.dialogue.response = error.response;
    return Promise.reject(error);
  }
);

createApp(App)
  .use(router)
  .use(i18n)
  .use(Vue3Lottie)
  .use(VueAxios, axios)
  .use(createPinia())
  .mount("#app");
